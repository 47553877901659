<template>
  <div class="footer-container">
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <div class="md-center display-flex align-items-center justify-content-flex-start height-100">
            <div class="content-image">
              <img
                src="@/assets/images/logo-bg-white.svg"
                alt=""
              >
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div class="display-flex align-items-center justify-content-center height-100">
            <p class="text-description mon-regular">{{ texts.footer.textDescription }}</p>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <div class="md-center display-flex align-items-center justify-content-flex-end height-100">
            <div class="display-flex align-items-center mr-1">
              <v-icon
                class="ml-1"
                color="#6e7d94"
                size="16px"
              >
                mdi-facebook
              </v-icon>
              <p
                class="text-social-media mon-regular text-link"
                @click="redirect('https://www.facebook.com/people/Proplat-Proplat/100073376927733/')"
              >
                Facebook
              </p>
            </div>
            <div class="display-flex align-items-center ml-1">
              <v-icon
                class="ml-1"
                color="#6e7d94"
                size="16px"
              >
                mdi-instagram
              </v-icon>
              <p
                class="text-social-media mon-regular text-link"
                @click="redirect('https://www.instagram.com/proplat.inc/')"
              >
                Instagram
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div style="border: 1px solid #BEBEBE;" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <div class="display-flex align-items-center justify-content-flex-start sm-center">
            <p
              class="text-social-media mon-regular text-link"
              @click="redirect('https://proplat-public-images.s3.amazonaws.com/PROPLAT-TERMINOS_Y_CONDICIONES.pdf')"
            >
              {{ texts.footer.conditions }}
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <div class="display-flex align-items-center justify-content-center sm-center">
            <p
              class="text-social-media mon-regular text-link"
              @click="redirect('https://proplat-public-images.s3.amazonaws.com/PROPLAT-TERMINOS_Y_CONDICIONES.pdf')"
            >
              {{ texts.footer.reserved }}
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <div class="display-flex align-items-center justify-content-flex-end sm-center">
            <p
              class="text-social-media mon-regular text-link"
              @click="redirect('https://sofex.com.mx/')"
            >
              {{ texts.footer.powered }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.footer[this.selectLanguage];
  },
  methods: {
    redirect: function (link) {
      window.open(link, "blank");
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.footer[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.text-link:hover {
  text-decoration: underline;
  color: #0071ff;
  cursor: pointer;
}

.text-social-media {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #6e7d94;
  opacity: 1;
  margin-bottom: 0px;
}

.text-description {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #6e7d94;
  opacity: 1;
  margin-bottom: 0px;
}

.content-image {
  width: 150px;
  height: 32px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.footer-container {
  background-color: #ffffff;
  padding: 10px 70px;
}

@media (max-width: 960px) {
  .md-center {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .sm-center {
    justify-content: center;
  }
  .footer-container {
    padding: 10px 0px;
  }
}
</style>